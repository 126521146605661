import React from 'react'
import { languages } from './index'
import messages from './messages'
import SocialTags from '../components/socialTags'

// Checks if input starts with string
function startsWith(input, string) {
  return input.indexOf(string) === 0
}

function getBrowserLang() {
  if (typeof window === 'undefined') {
    return null
  }

  return (
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language ||
    window.navigator.browserLanguage ||
    window.navigator.userLanguage ||
    window.navigator.systemLanguage ||
    null
  )
}

function normalizeCode(code) {
  return code.toLowerCase().replace(/-/, '_')
}

function getPreferredLanguage(options) {
  if (!options) {
    return getBrowserLang()
  }

  const { languages, fallback } = options
  if (!options.languages) {
    return fallback
  }

  // some browsers report language as en-US instead of en_US
  const browserLanguage = normalizeCode(getBrowserLang())

  if (!browserLanguage) {
    return fallback
  }

  const match = languages.filter(
    lang => normalizeCode(lang) === browserLanguage
  )

  if (match.length > 0) {
    return match[0] || fallback
  }

  // en == en_US
  const matchCodeOnly = languages.filter(lang =>
    startsWith(browserLanguage, lang)
  )
  return matchCodeOnly[0] || fallback
}

class Redirect extends React.Component {
  constructor(props) {
    super(props)

    const langKeys = languages.map(language => language.value)
    const { pathname, hash } = props.location

    // Skip build, Browsers only
    if (typeof window !== 'undefined') {
      const detected =
        window.localStorage.getItem('locale_language') ||
        getPreferredLanguage({
          languages: langKeys,
          fallback: 'en',
        })

      window.localStorage.setItem('locale_language', detected)
      window.location.replace(`/${detected}${pathname}${hash}`)
    }
  }
  render() {
    return (
      <SocialTags
        pageTitle={messages.en['landing.meta.title']}
        description={messages.en['meta.description']}
        metaImgUrl="https://makerdao.com/dai.png"
      />
    )
  }
}

export default Redirect
